import { z } from "zod";

export const genderSchema = z.enum(["M", "F", "X"]);

export const leagueResultSchema = z.object({
  id: z.number(),
  group_name: z.string().nullable(),
  name: z.string(),
  slug: z.string(),
  description: z.string().nullable(),
  gender: z.enum(["M", "F", "X"]),
  country: z.string().nullable(),
  is_active: z.boolean(),
  order_index: z.number(),
});

export const leagueCreateSchema = z.object({
  sport_id: z.number(),
  group_name: z.string().nullable(),
  name: z.string(),
  slug: z.string(),
  description: z.string().nullable(),
  gender: genderSchema.default("X"),
  country: z.string().nullable(),
  is_active: z.boolean().default(true),
});

export const leagueUpdateSchema = z.object({
  id: z.number(),
  group_name: z.string().nullable(),
  name: z.string().nullable(),
  slug: z.string().nullable(),
  description: z.string().nullable(),
  gender: genderSchema.nullable(),
  country: z.string().nullable(),
  is_active: z.boolean().nullable(),
});

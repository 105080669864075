import { z } from "zod";

export const sourceEnum = z.enum(["thesportsdb", "manual"]);

export const tournamentSeasonResultSchema = z.object({
  id: z.number(),
  tournament_id: z.number(),
  name: z.string(),
  slug: z.string(),
  season: z.string(),
  start_date: z.string().datetime(), // ISO 8601 datetime string
  end_date: z.string().datetime(), // ISO 8601 datetime string
  source: sourceEnum,
  update_interval: z.number().nullable().optional(),
  last_update: z.string().datetime().nullable().optional(), // ISO 8601 datetime string
  sdb_league_id: z.number().nullable().optional(),
  sdb_season: z.string().nullable().optional(),
});

export const tournamentSeasonCreateSchema = z.object({
  tournament_id: z.number(),
  name: z.string(),
  slug: z.string(),
  season: z.string(),
  start_date: z.string().datetime(), // ISO 8601 datetime string
  end_date: z.string().datetime(), // ISO 8601 datetime string
  source: sourceEnum,
  sdb_league_id: z.number().nullable().optional(),
  sdb_season: z.string().nullable().optional(),
  update_interval: z.number().nullable().optional(),
});

export const tournamnetSeasonUpdateSchema = z.object({
  id: z.number(),
  name: z.string().nullable().optional(),
  slug: z.string().nullable().optional(),
  season: z.string().nullable().optional(),
  start_date: z.string().datetime().nullable().optional(),
  end_date: z.string().datetime().nullable().optional(),
  source: sourceEnum.nullable().optional(),
  sdb_league_id: z.number().nullable().optional(),
  sdb_season: z.string().nullable().optional(),
  update_interval: z.number().nullable().optional(),
});

export const tournamentSeasonSyncResultSchema = z.object({
  tournament_season_id: z.number(),
  tournament_season_name: z.string().nullable().optional(),
  added: z.number().default(0),
  updated: z.number().default(0),
});

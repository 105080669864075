import React, { useState, useEffect, useMemo } from "react";
import {
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";

import { useGetTournamentsByLeagueId } from "../queries";
import { useUpdateTournament, useAddTournament } from "../mutations";
import AddTournamentModal from "./AddTournamentModal";
import { useGetAllLeagues } from "../../leagues/queries";

const TournamentOverview = () => {
  const { data: leagues, isLoading: leaguesLoading } = useGetAllLeagues();
  const [selectedLeagueId, setSelectedLeagueId] = useState<number | undefined>(
    undefined
  );
  const [submittedLeagueId, setSubmittedLeagueId] = useState<
    number | undefined
  >(undefined);
  const handleFetchData = () => setSubmittedLeagueId(selectedLeagueId);
  const { data, isLoading, error, refetch } =
    useGetTournamentsByLeagueId(submittedLeagueId);
  const [rows, setRows] = useState<any[]>([]);
  const { mutate: updateTournament } = useUpdateTournament();
  const { mutate: createTournament } = useAddTournament();
  const [open, setOpen] = useState(false);
  const [newTournament, setNewTournament] = useState({
    league_id: 1,
    name: "",
    slug: "",
    country: null as string | null,
  });

  useEffect(() => {
    if (data) setRows(data);
  }, [data]);

  const processRowUpdate = async (newRow: any, oldRow: any) => {
    updateTournament(newRow);
    return newRow;
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleAddTournament = () => {
    if (!newTournament.name || !newTournament.slug) return;
    createTournament(newTournament, {
      onSuccess: (addedTournament) => {
        setRows((prev) => [...prev, addedTournament]);
        setNewTournament({ league_id: 1, name: "", slug: "", country: null });
        handleClose();
      },
    });
  };
  const ParameterSelection = () => {
    // Otherwise render form
    return (
      <Box display="block" mb={3}>
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <InputLabel id="league-id">League ID</InputLabel>
          <Select
            labelId="league-id"
            id="league-id"
            value={selectedLeagueId}
            label="League ID"
            onChange={(e) => {
              setNewTournament((prev) => ({
                ...prev,
                league_id: Number(e.target.value),
              }));
              setSelectedLeagueId(Number(e.target.value));
            }}
            disabled={leaguesLoading} // Disable dropdown while loading
          >
            {leaguesLoading ? (
              <MenuItem disabled>
                <CircularProgress size={24} />
              </MenuItem>
            ) : (
              leagues?.map((league) => (
                <MenuItem key={league.id} value={league.id}>
                  {league.id} {league.name} - {league.country} ({league.gender})
                </MenuItem>
              ))
            )}
          </Select>
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          onClick={handleFetchData}
          disabled={!selectedLeagueId}
          fullWidth
        >
          Search
        </Button>
      </Box>
    );
  };

  const columns: GridColDef[] = useMemo(
    () => [
      { field: "id", headerName: "ID", width: 70 },
      { field: "name", headerName: "Name", width: 200, editable: true },
      { field: "slug", headerName: "Slug", width: 150, editable: true },
      {
        field: "country",
        headerName: "Country",
        width: 100,
        editable: true,
      },
      {
        field: "is_active",
        headerName: "Active",
        width: 150,
        editable: true,
        type: "boolean",
      },
    ],
    []
  );

  const CustomToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarColumnsButton
        slotProps={{ button: { color: "secondary" } }}
      />
      <GridToolbarFilterButton slotProps={{ button: { color: "secondary" } }} />
      <IconButton onClick={handleOpen} color="secondary">
        <AddIcon />
      </IconButton>
      <IconButton onClick={() => refetch()} color="secondary">
        <RefreshIcon />
      </IconButton>
    </GridToolbarContainer>
  );

  if (isLoading) return <CircularProgress />;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div>
      <ParameterSelection />
      <AddTournamentModal
        open={open}
        onClose={handleClose}
        newTournament={newTournament}
        setNewTournament={setNewTournament}
        handleAddTournament={handleAddTournament}
      />
      <Box sx={{ height: 500, width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          editMode="row"
          processRowUpdate={processRowUpdate}
          slots={{ toolbar: CustomToolbar }}
          onProcessRowUpdateError={(error) =>
            console.error("Row update error:", error)
          }
        />
      </Box>
    </div>
  );
};

export default TournamentOverview;

import { z } from "zod";

import { metaIconResultSchema } from "./schema";
import axios from "../../api/axiosInstance";
import { MetaUploadIcon } from "./types";

export const fetchAllLeagueIcons = async () => {
  const { data } = await axios.get("/metadata/icons/leagues");
  return z.array(metaIconResultSchema).parse(data);
};

export const uploadIcon = async (icon: MetaUploadIcon) => {
  const { data } = await axios.post("/metadata/icon", icon);
  return z.boolean().parse(data);
};

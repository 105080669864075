import { useQuery } from "@tanstack/react-query";

import { fetchAllSports } from "./api";
import { SportResult } from "./types";

/**
 * React Query hook to fetch all sports.
 */
export const useGetAllSports = () => {
  return useQuery<SportResult[]>({
    queryKey: ["sports"],
    queryFn: () => fetchAllSports(),
  });
};

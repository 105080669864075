import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
} from "@mui/material";
import { MetaUploadIcon } from "../types";

interface AddIconModalProps {
  open: boolean;
  onClose: () => void;
  newIcon: MetaUploadIcon;
  setNewIcon: (league: MetaUploadIcon) => void;
  handleAddNewIcon: () => void;
  createError: string | null;
}

const AddIconModal: React.FC<AddIconModalProps> = ({
  open,
  onClose,
  newIcon,
  setNewIcon,
  handleAddNewIcon,
  createError,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add or update icon</DialogTitle>
      {createError && <div>Error adding: {createError}</div>}
      <DialogContent>
        <TextField
          margin="dense"
          label="Type"
          fullWidth
          disabled
          value={newIcon.icon_type}
        />
        <TextField
          select
          margin="dense"
          label="Theme"
          fullWidth
          disabled
          value={newIcon.icon_theme}
          onChange={(e) =>
            setNewIcon({
              ...newIcon,
              icon_theme: e.target.value as "dark" | "light",
            })
          }
          SelectProps={{
            native: true,
          }}
        >
          <option value="light">Light</option>
          <option value="dark">Dark</option>
        </TextField>
        <TextField
          margin="dense"
          label="Slug"
          disabled
          fullWidth
          value={newIcon.slug}
          onChange={(e) => setNewIcon({ ...newIcon, slug: e.target.value })}
        />
        <TextField
          margin="dense"
          label="Source URL"
          fullWidth
          value={newIcon.url}
          onChange={(e) => setNewIcon({ ...newIcon, url: e.target.value })}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleAddNewIcon} variant="contained" color="primary">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddIconModal;

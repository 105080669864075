import SettingsMenuBar from "../../components/SettingsMenu";
import SportsOverview from "../../features/sports/components/sportsOverview";

const SportsPage = () => {
  return (
    <div>
      <SettingsMenuBar />
      <h1>Sports</h1>
      <SportsOverview />
    </div>
  );
};

export default SportsPage;

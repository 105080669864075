import axios from "axios";

// Create an axios instance
const apiUrl = process.env.REACT_APP_API_URL + "/v2";
const client = axios.create({
  baseURL: apiUrl, // Your API base URL
});

client.interceptors.request.use(
  (config) => {
    const authKey = localStorage.getItem("authKey");
    if (authKey) {
      config.headers.Authorization = "Bearer " + authKey;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export default client;

import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  MenuItem,
  CircularProgress,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import { TournamentCreate } from "../types"; // Adjust the import as needed
import { useGetAllLeagues } from "../../leagues/queries";

interface AddTournamentModalProps {
  open: boolean;
  onClose: () => void;
  newTournament: TournamentCreate;
  setNewTournament: (tournament: TournamentCreate) => void;
  handleAddTournament: () => void;
}

const AddTournamentModal: React.FC<AddTournamentModalProps> = ({
  open,
  onClose,
  newTournament,
  setNewTournament,
  handleAddTournament,
}) => {
  const {
    data: leagues,
    isLoading: leaguesLoading,
    error: leaguesError,
  } = useGetAllLeagues();
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add New Tournament</DialogTitle>
      <DialogContent>
        <FormControl fullWidth margin="normal">
          <InputLabel id="tournament-id">Tournament</InputLabel>
          <Select
            labelId="tournament-id"
            id="tournament-id"
            value={newTournament.league_id}
            label="Tournament"
            onChange={(e) =>
              setNewTournament({
                ...newTournament,
                league_id: Number(e.target.value),
              })
            }
            disabled={leaguesLoading}
          >
            {leaguesLoading ? (
              <MenuItem disabled>
                <CircularProgress size={24} />
              </MenuItem>
            ) : leaguesError ? (
              <MenuItem disabled>Error loading</MenuItem>
            ) : (
              leagues?.map((league) => (
                <MenuItem key={league.id} value={league.id}>
                  {league.id} {league.name} - {league.country} ({league.gender})
                </MenuItem>
              ))
            )}
          </Select>
        </FormControl>
        <TextField
          margin="dense"
          label="Name"
          fullWidth
          value={newTournament.name}
          onChange={(e) =>
            setNewTournament({ ...newTournament, name: e.target.value })
          }
        />
        <TextField
          margin="dense"
          label="Slug"
          fullWidth
          value={newTournament.slug}
          onChange={(e) =>
            setNewTournament({ ...newTournament, slug: e.target.value })
          }
        />
        <TextField
          margin="dense"
          label="Country"
          fullWidth
          value={newTournament.country}
          onChange={(e) =>
            setNewTournament({ ...newTournament, country: e.target.value })
          }
        />
        {/* Additional fields can be added here if necessary */}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleAddTournament}
          variant="contained"
          color="primary"
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddTournamentModal;

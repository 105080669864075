import SettingsMenuBar from "../../components/SettingsMenu";
import TournamentOverview from "../../features/tournaments/components/TournamentOverview";

const TournamentsPage = () => {
  return (
    <div>
      <SettingsMenuBar />
      <h1>Tournaments</h1>
      <TournamentOverview />
    </div>
  );
};

export default TournamentsPage;

import { z } from "zod";

const IconTypeSchema = z.enum(["league"]);
const IconThemeSchema = z.enum(["dark", "light"]);

export const metaIconResultSchema = z.string();

export const metaUploadIconSchema = z.object({
  icon_type: IconTypeSchema,
  icon_theme: IconThemeSchema,
  slug: z.string(),
  url: z.string().url(),
});

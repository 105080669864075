import { useMutation } from "@tanstack/react-query";
import { addLeague, updateLeague } from "./api";

export function useAddLeague() {
  return useMutation({
    mutationFn: addLeague,
  });
}

export function useUpdateLeague() {
  return useMutation({
    mutationFn: updateLeague,
  });
}

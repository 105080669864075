import { z } from "zod";

export const tournamentCreateSchema = z.object({
  league_id: z.number(),
  name: z.string(),
  slug: z.string(),
  country: z.string().nullable(),
});

export const tournamentUpdateSchema = z.object({
  id: z.number(),
  name: z.string().nullable(),
  slug: z.string().nullable(),
  country: z.string().nullable(),
  is_active: z.boolean().nullable(),
});

export const tournamentResultSchema = z.object({
  id: z.number(),
  name: z.string(),
  slug: z.string(),
  league_id: z.number(),
  country: z.string().nullable(),
  is_active: z.boolean(),
});

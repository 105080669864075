import { useMutation } from "@tanstack/react-query";
import {
  createTournamentSeason,
  syncTournamentSeasonEvents,
  updateTournamentSeason,
} from "./api";

export function useSyncTournamentSeasonEvents() {
  return useMutation({
    mutationFn: syncTournamentSeasonEvents,
  });
}

export function useCreateTournamentSeason() {
  return useMutation({
    mutationFn: createTournamentSeason,
  });
}

export function useUpdateTournamentSeason() {
  return useMutation({
    mutationFn: updateTournamentSeason,
  });
}

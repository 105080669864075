import { z } from "zod";

import { sportResultSchema } from "./schema";
import axios from "../../api/axiosInstance";
import { SportCreate, SportUpdate } from "./types";

export const fetchAllSports = async () => {
  const { data } = await axios.get("/sport");
  return z.array(sportResultSchema).parse(data);
};

export const createSport = async (sport: SportCreate) => {
  const { data } = await axios.post("/sport", sport);
  return sportResultSchema.parse(data);
};

export const updateSport = async (sport: SportUpdate) => {
  const { data } = await axios.put(`/sport/${sport.id}`, sport);
  return sportResultSchema.parse(data);
};

export const addLeague = async (name: string) => {
  const { data } = await axios.post("/league", { name });
  return sportResultSchema.parse(data);
};

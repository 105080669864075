import { useQuery } from "@tanstack/react-query";

import { configApi } from "./api";
import { ConfigIsAuthResultSchema } from "./types";

export const useIsAuthenticated = () => {
  return useQuery<ConfigIsAuthResultSchema>({
    queryKey: ["config", "is-auth"],
    queryFn: () => configApi.getIsAuthenticated(),
  });
};

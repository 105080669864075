import { useQuery } from "@tanstack/react-query";

import { TournamentResult } from "./types";
import { fetchTournamentsByLeagueId } from "./api";

export const useGetTournamentsByLeagueId = (leagueId?: number) => {
  return useQuery<TournamentResult[]>({
    queryKey: ["tournaments", "by-league-id", leagueId],
    queryFn: () => fetchTournamentsByLeagueId(leagueId),
  });
};

import {
  CircularProgress,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Divider,
  Grid,
} from "@mui/material";
import { useGetAllLeagues } from "../../leagues/queries";
import { useState } from "react";
import { MetaUploadIcon } from "../types";
import { LeagueResult } from "../../leagues/types";
import AddIconModal from "./AddIconModal";
import { useUploadIcon } from "../mutations";

const IconOverview = () => {
  const { data: leagues, isLoading: iconsLoading } = useGetAllLeagues();
  const { mutate: updateIcon } = useUploadIcon();
  const [newIcon, setNewIcon] = useState<MetaUploadIcon>({
    icon_type: "league",
    icon_theme: "light",
    slug: "",
    url: "",
  });
  const [showModal, setShowModal] = useState(false);
  const getIconUrl = (leagueSlug: string, theme: string) =>
    `https://assets.sportstoday.app/league-${theme}-${leagueSlug}.png`;

  const handleClick = (league: LeagueResult, theme: "dark" | "light") => {
    setNewIcon({
      ...newIcon,
      slug: league.slug,
      icon_theme: theme,
    });
    setShowModal(true);
    console.log(league.id);
  };
  const handleUpdateIcon = () => {
    updateIcon(newIcon, {
      onSuccess: (addedLeague) => {
        setNewIcon({
          icon_type: "league",
          icon_theme: "light",
          slug: "",
          url: "",
        });
        setShowModal(false);
      },
    });
  };
  return (
    <div>
      <AddIconModal
        open={showModal}
        onClose={() => setShowModal(false)}
        newIcon={newIcon}
        setNewIcon={setNewIcon}
        handleAddNewIcon={handleUpdateIcon}
        createError={null}
      />
      {iconsLoading ? (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ minHeight: "100vh" }}
        >
          <CircularProgress />
        </Grid>
      ) : (
        <Grid container spacing={3} padding={3}>
          {leagues?.map((league) => (
            <Grid item xs={12} sm={6} md={4} key={league.id}>
              <Card>
                <CardContent>
                  <Typography variant="h6" align="center" gutterBottom>
                    {league.name} - {league.country} ({league.gender})
                  </Typography>
                  <Divider />
                  <br />
                  <Grid container justifyContent="center" spacing={2}>
                    <Grid
                      item
                      style={{
                        backgroundColor: "white",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: 10,
                        padding: 20,
                        borderWidth: 2,
                        borderRadius: 5,
                        borderColor: "black",
                        borderStyle: "solid",
                      }}
                      onClick={() => handleClick(league, "light")}
                    >
                      <CardMedia
                        component="img"
                        src={getIconUrl(league.slug, "light")}
                        alt={`${league.name} Light Icon`}
                        style={{ height: 50 }}
                      />
                    </Grid>

                    <Grid
                      item
                      style={{
                        backgroundColor: "black",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: 10,
                        padding: 20,
                        borderWidth: 2,
                        borderRadius: 5,
                        borderColor: "black",
                      }}
                      onClick={() => handleClick(league, "dark")}
                    >
                      <CardMedia
                        component="img"
                        src={getIconUrl(league.slug, "dark")}
                        alt={`${league.name} Dark Icon`}
                        style={{ height: 50 }}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </div>
  );
};

export default IconOverview;

import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  CircularProgress,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { LeagueCreate } from "../types"; // Adjust type import as needed
import { useGetAllSports } from "../../sports/queries";

interface AddLeagueModalProps {
  open: boolean;
  onClose: () => void;
  newLeague: LeagueCreate;
  setNewLeague: (league: LeagueCreate) => void;
  handleAddLeague: () => void;
  createError: string | null;
}

const AddLeagueModal: React.FC<AddLeagueModalProps> = ({
  open,
  onClose,
  newLeague,
  setNewLeague,
  handleAddLeague,
  createError,
}) => {
  const {
    data: sports,
    isLoading: sportIsLoading,
    error: sportsError,
  } = useGetAllSports();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add New League</DialogTitle>
      {sportsError && <div>Error loading sports</div>}
      {createError && <div>Error adding: {createError}</div>}
      <DialogContent>
        <FormControl fullWidth margin="normal">
          <InputLabel id="sport-id">Sport</InputLabel>
          <Select
            labelId="sport-id"
            id="sport-id"
            value={newLeague.sport_id}
            label="Sport"
            onChange={(e) =>
              setNewLeague({ ...newLeague, sport_id: e.target.value as number })
            }
            disabled={sportIsLoading} // Disable dropdown while loading
          >
            {sportIsLoading ? (
              <MenuItem disabled>
                <CircularProgress size={24} />
              </MenuItem>
            ) : (
              [
                <MenuItem key="none" value={undefined} disabled>
                  <em>None</em>
                </MenuItem>,
                ...(sports || []).map((sport) => (
                  <MenuItem key={sport.id} value={sport.id}>
                    {sport.name}
                  </MenuItem>
                )),
              ]
            )}
          </Select>
        </FormControl>
        <TextField
          margin="dense"
          label="Name"
          fullWidth
          value={newLeague.name}
          onChange={(e) => setNewLeague({ ...newLeague, name: e.target.value })}
        />
        <TextField
          margin="dense"
          label="Slug"
          fullWidth
          value={newLeague.slug}
          onChange={(e) => setNewLeague({ ...newLeague, slug: e.target.value })}
        />
        <TextField
          margin="dense"
          label="Description"
          fullWidth
          value={newLeague.description || ""}
          onChange={(e) =>
            setNewLeague({ ...newLeague, description: e.target.value || null })
          }
        />
        <TextField
          margin="dense"
          label="Country"
          fullWidth
          value={newLeague.country || ""}
          onChange={(e) =>
            setNewLeague({ ...newLeague, country: e.target.value || null })
          }
        />
        <FormControl fullWidth margin="normal">
          <InputLabel id="gender">Gender</InputLabel>
          <Select
            labelId="gender"
            id="gender"
            value={newLeague.gender}
            label="Gender"
            onChange={(e) =>
              setNewLeague({
                ...newLeague,
                gender: e.target.value as "M" | "F" | "X",
              })
            }
          >
            <MenuItem value="M">Male</MenuItem>
            <MenuItem value="F">Female</MenuItem>
            <MenuItem value="X">Mixed</MenuItem>
          </Select>
        </FormControl>
        <FormControlLabel
          control={
            <Checkbox
              checked={newLeague.is_active}
              onChange={(e) =>
                setNewLeague({ ...newLeague, is_active: e.target.checked })
              }
            />
          }
          label="Active"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleAddLeague} variant="contained" color="primary">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddLeagueModal;

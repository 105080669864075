import SettingsMenuBar from "../../components/SettingsMenu";
import DeleteCacheButton from "../../features/cache/components/deleteCacheButton";

const ConfigPage = () => {
  return (
    <div>
      <SettingsMenuBar />
      <h1>Config</h1>
      <DeleteCacheButton />
    </div>
  );
};

export default ConfigPage;

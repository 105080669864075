import {
  configDeleteCacheResultSchema,
  configIsAuthResultSchema,
} from "./schema";
import axios from "../../api/axiosInstance";

/**
 * Delete league and event cache.
 */
const deleteCache = async () => {
  const { data } = await axios.delete("/config/cache");
  return configDeleteCacheResultSchema.parse(data);
};

const getIsAuthenticated = async () => {
  const { data } = await axios.get("/config/is-auth");
  return configIsAuthResultSchema.parse(data);
};
export const configApi = {
  deleteCache,
  getIsAuthenticated,
};

import { z } from "zod";

export const eventResultSchema = z.object({
  id: z.number(),
  tournament_season_id: z.number(),
  tournament_name: z.string().optional().nullable(),
  name: z.string(),
  start_time: z.string().datetime(), // ISO 8601 datetime string
  venue: z.string().nullable(),
  stage: z.string().nullable(),
  stage_type: z.string().nullable(),
  group: z.string().nullable(),
  country: z.string().nullable(),
  distance: z.number().nullable(),
  start_location: z.string().nullable(),
  finish_location: z.string().nullable(),
  home_team_id: z.number().nullable(),
  away_team_id: z.number().nullable(),
  home_team_name: z.string().nullable(),
  away_team_name: z.string().nullable(),
  home_team_icon: z.string().url().nullable(),
  away_team_icon: z.string().url().nullable(),
  home_team_score: z.number().nullable(),
  away_team_score: z.number().nullable(),
});

export const eventUpdateSchema = z.object({
  id: z.number(),
  name: z.string().nullable().optional(),
  start_time: z.string().datetime().nullable().optional(),
  venue: z.string().nullable().optional(),
  stage: z.string().nullable().optional(),
  stage_type: z.string().nullable().optional(),
  group: z.string().nullable().optional(),
  country: z.string().nullable().optional(),
  distance: z.number().nullable().optional(),
  start_location: z.string().nullable().optional(),
  finish_location: z.string().nullable().optional(),
});

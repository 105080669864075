import React, { useState, useEffect, useMemo } from "react";
import {
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { Box, CircularProgress, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";

import { LeagueCreate, LeagueResult } from "../types";
import { useGetAllLeagues } from "../queries";
import { useUpdateLeague, useAddLeague } from "../mutations";
import AddLeagueModal from "./AddLeagueModal";

const LeagueOverview = () => {
  const { data, isLoading, error, refetch } = useGetAllLeagues();
  const [rows, setRows] = useState<LeagueResult[]>([]);
  const { mutate: updateLeague } = useUpdateLeague();
  const { mutate: createLeague, error: createError } = useAddLeague();
  const [open, setOpen] = useState(false);
  const [newLeague, setNewLeague] = useState<LeagueCreate>({
    sport_id: 1,
    group_name: null,
    name: "",
    slug: "",
    description: null,
    gender: "X",
    country: null,
    is_active: true,
  });

  useEffect(() => {
    if (data) {
      console.log(data);
      setRows(data);
    }
  }, [data]);

  const processRowUpdate = async (newRow: any, oldRow: any) => {
    updateLeague(newRow);
    return newRow;
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleAddLeague = () => {
    createLeague(newLeague, {
      onSuccess: (addedLeague) => {
        setRows((prev) => [...prev, addedLeague]);
        setNewLeague({
          sport_id: 0,
          group_name: null,
          name: "",
          slug: "",
          description: null,
          gender: "X",
          country: null,
          is_active: true,
        });
        handleClose();
      },
    });
  };

  const columns: GridColDef[] = useMemo(
    () => [
      { field: "name", headerName: "Name", width: 200, editable: true },
      { field: "gender", headerName: "Gender", width: 100, editable: true },
      { field: "slug", headerName: "Slug", width: 150, editable: true },
      { field: "group_name", headerName: "Group", width: 150, editable: true },
      {
        field: "description",
        headerName: "Description",
        width: 300,
        editable: true,
      },
      { field: "country", headerName: "Country", width: 150, editable: true },
      {
        field: "is_active",
        headerName: "Active",
        width: 100,
        editable: true,
      },
    ],
    []
  );

  const CustomToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarColumnsButton
        slotProps={{ button: { color: "secondary" } }}
      />
      <GridToolbarFilterButton slotProps={{ button: { color: "secondary" } }} />
      <IconButton onClick={handleOpen} color="secondary">
        <AddIcon />
      </IconButton>
      <IconButton onClick={() => refetch()} color="secondary">
        <RefreshIcon />
      </IconButton>
    </GridToolbarContainer>
  );

  if (isLoading) return <CircularProgress />;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <Box sx={{ height: 500, width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        editMode="row"
        processRowUpdate={processRowUpdate}
        slots={{ toolbar: CustomToolbar }}
        onProcessRowUpdateError={(error) =>
          console.error("Row update error:", error)
        }
      />
      <AddLeagueModal
        open={open}
        onClose={handleClose}
        newLeague={newLeague}
        setNewLeague={setNewLeague}
        handleAddLeague={handleAddLeague}
        createError={createError?.message || null}
      />
    </Box>
  );
};

export default LeagueOverview;

import { useMutation } from "@tanstack/react-query";
import { addTournament, updateTournament } from "./api";

export function useAddTournament() {
  return useMutation({
    mutationFn: addTournament,
  });
}

export function useUpdateTournament() {
  return useMutation({
    mutationFn: updateTournament,
  });
}

import SettingsMenuBar from "../../components/SettingsMenu";
import IconOverview from "../../features/metadata/components/IconOverview";

const MetadataPage = () => {
  return (
    <div>
      <SettingsMenuBar />
      <h1>Metadata</h1>
      <IconOverview />
    </div>
  );
};

export default MetadataPage;

import React, { useEffect, useState, useMemo } from "react";
import { useEventResultsByLeagueId } from "../queries";
import {
  DataGrid,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { useUpdateEvent } from "../mutations";
import { useGetAllLeagues } from "../../leagues/queries";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";
import EditIcon from "@mui/icons-material/Edit";
import FilterListIcon from "@mui/icons-material/FilterList";
import ClearIcon from "@mui/icons-material/Clear";
import { useGetTournamentsByLeagueId } from "../../tournaments/queries";

const EventsPerLeagueId: React.FC = () => {
  // States used in form
  const [leagueId, setLeagueId] = useState<number | undefined>(undefined);
  const [tournamentId, setTournamentId] = useState<number | undefined>(
    undefined
  );
  const [tournamentSeasonId, setTournamentSeasonId] = useState<
    number | undefined
  >(undefined);
  const [startDate, setStartDate] = useState<string | undefined>(
    new Date().toISOString().split("T")[0]
  );
  // Default to 1 week from today
  const [endDate, setEndDate] = useState<string | undefined>(
    new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString().split("T")[0]
  );

  // States used in fetching data
  const [submittedId, setSubmittedId] = useState<number | undefined>(undefined);
  const [submittedTournamentId, setSubmittedTournamentId] = useState<
    number | undefined
  >(undefined);
  const [submittedTournamentSeasonId, setSubmittedTournamentSeasonId] =
    useState<number | undefined>(undefined);
  const [submittedStartDate, setSubmittedStartDate] = useState<
    string | undefined
  >(undefined);
  const [submittedEndDate, setSubmittedEndDate] = useState<string | undefined>(
    undefined
  );

  const [editedRows, setEditedRows] = useState<any[] | undefined>(undefined);

  // State for JSON modal
  const [openJsonModal, setOpenJsonModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [jsonInput, setJsonInput] = useState<string>("");

  // Fetch all leagues
  const {
    data: leagues,
    isLoading: leaguesLoading,
    error: leaguesError,
  } = useGetAllLeagues();

  const { data: tournaments, isLoading: tournamentsLoading } =
    useGetTournamentsByLeagueId(leagueId);

  // Fetch events by league id and date range
  const { data, isLoading, error, refetch } = useEventResultsByLeagueId(
    submittedId,
    submittedTournamentId,
    submittedTournamentSeasonId,
    submittedStartDate,
    submittedEndDate
  );

  const {
    mutate: update,
    isError: isUpdateError,
    error: updateError,
    isSuccess: isUpdateSuccess,
  } = useUpdateEvent();

  const handleFetchData = () => {
    setSubmittedId(leagueId);
    setSubmittedTournamentId(tournamentId);
    setSubmittedTournamentSeasonId(tournamentSeasonId);
    setSubmittedStartDate(startDate);
    setSubmittedEndDate(endDate);
  };

  const handleSelectTournamentSeason = (id: number) => {
    setTournamentSeasonId(id);
    setStartDate(undefined);
    setEndDate(undefined);
  };

  useEffect(() => {
    if (data) {
      setEditedRows(data);
    }
  }, [data]);

  const CustomToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarColumnsButton
        slotProps={{ button: { color: "secondary" } }}
      />
      <GridToolbarFilterButton slotProps={{ button: { color: "secondary" } }} />
      <IconButton onClick={() => console.log("Add event")} color="secondary">
        <AddIcon />
      </IconButton>
      <IconButton onClick={() => refetch()} color="secondary">
        <RefreshIcon />
      </IconButton>
    </GridToolbarContainer>
  );

  const ParameterSelection = () => {
    // Show error when loading leagues fails
    if (leaguesError)
      return <Alert severity="error">{leaguesError.message}</Alert>;

    // Otherwise render form
    return (
      <Box display="block" mb={3}>
        <FormControl fullWidth margin="normal">
          <InputLabel id="league-id">League ID</InputLabel>
          <Select
            labelId="league-id"
            id="league-id"
            value={leagueId}
            label="League ID"
            onChange={(e) => {
              setLeagueId(Number(e.target.value));
              setTournamentId(undefined);
            }}
            disabled={leaguesLoading} // Disable dropdown while loading
          >
            {leaguesLoading ? (
              <MenuItem disabled>
                <CircularProgress size={24} />
              </MenuItem>
            ) : (
              [
                <MenuItem key="none" value={undefined} disabled>
                  <em>None</em>
                </MenuItem>,
                ...(leagues || []).map((league) => (
                  <MenuItem key={league.id} value={league.id}>
                    {league.id} {league.name} - {league.country} (
                    {league.gender})
                  </MenuItem>
                )),
              ]
            )}
          </Select>
        </FormControl>
        <FormControl fullWidth margin="normal">
          <InputLabel id="tournament-id">Tournament ID</InputLabel>
          <Select
            labelId="tournament-id"
            id="tournament-id"
            value={tournamentId}
            label="Tournament ID"
            onChange={(e) => setTournamentId(Number(e.target.value))}
            disabled={tournamentsLoading} // Disable dropdown while loading
          >
            {tournamentsLoading ? (
              <MenuItem disabled>
                <CircularProgress size={24} />
              </MenuItem>
            ) : (
              [
                <MenuItem key="none" value={undefined} disabled>
                  <em>None</em>
                </MenuItem>,
                ...(tournaments || []).map((tournament) => (
                  <MenuItem key={tournament.id} value={tournament.id}>
                    {tournament.id} {tournament.name}
                  </MenuItem>
                )),
              ]
            )}
          </Select>
        </FormControl>
        <TextField
          label="Tournament Season ID"
          type="number"
          value={tournamentSeasonId}
          fullWidth
          margin="normal"
          disabled
          slotProps={{
            input: {
              endAdornment: (
                <IconButton onClick={() => setTournamentSeasonId(undefined)}>
                  <ClearIcon />
                </IconButton>
              ),
            },
          }}
        />
        <TextField
          label="Start Date"
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="End Date"
          type="date"
          value={endDate || ""}
          onChange={(e) => setEndDate(e.target.value)}
          fullWidth
          margin="normal"
        />
        <Box display="flex" justifyContent="space-between" mt={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleFetchData}
            disabled={!leagueId && !startDate && !endDate}
            fullWidth
          >
            Search
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => {
              setLeagueId(undefined);
              setTournamentId(undefined);
              setTournamentSeasonId(undefined);
              setStartDate(undefined);
              setEndDate(undefined);
            }}
            fullWidth
          >
            Clear
          </Button>
        </Box>
      </Box>
    );
  };

  const DataTable = () => {
    if (isLoading) return <p>Loading...</p>;
    if (error) return <p>{error.message}</p>;
    if (!editedRows) return null;

    return (
      <div style={{ height: 500, width: "100%" }}>
        <DataGrid
          editMode="row"
          rows={editedRows}
          columns={eventColumns}
          slots={{ toolbar: CustomToolbar }}
          processRowUpdate={processRowUpdate} // Call mutation when editing
          onProcessRowUpdateError={(error) =>
            console.error("Row update error:", error)
          }
        />
      </div>
    );
  };

  const ItemUpdateSummary = () => {
    if (isUpdateError)
      return <Alert severity="error">{updateError.message}</Alert>;
    if (isUpdateSuccess)
      return <Alert severity="success">Update success</Alert>;
    return null;
  };

  const ItemEditDialog = () => {
    return (
      <Dialog open={openJsonModal} onClose={() => setOpenJsonModal(false)}>
        <DialogTitle>Edit Row Data (JSON)</DialogTitle>
        <DialogContent>
          <TextField
            label="JSON Data"
            multiline
            rows={4}
            fullWidth
            value={jsonInput}
            onChange={(e) => setJsonInput(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenJsonModal(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleJsonInputSubmit}
            color="primary"
            variant="contained"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const processRowUpdate = async (newRow: any, oldRow: any) => {
    try {
      update(newRow); // Call API to update
      setEditedRows((prev) =>
        prev?.map((row) => (row.id === newRow.id ? newRow : row))
      );

      return newRow; // Return updated row
    } catch (error) {
      console.error("Error updating row:", error);
      return oldRow; // Revert on failure
    }
  };

  const handleJsonInputSubmit = () => {
    try {
      const parsedData = JSON.parse(jsonInput);
      const updatedRow = { ...selectedRow, ...parsedData }; // Merge old row with new data

      update(updatedRow); // Update the row
      setEditedRows((prev) =>
        prev?.map((row) => (row.id === updatedRow.id ? updatedRow : row))
      );

      setOpenJsonModal(false); // Close the modal
    } catch (error) {
      console.error("Invalid JSON input", error);
    }
  };

  const handleOpenJsonModal = (row: any) => {
    setSelectedRow(row);
    setOpenJsonModal(true);
  };

  const eventColumns: GridColDef[] = useMemo(
    () => [
      {
        field: "actions",
        headerName: "Actions",
        width: 100,
        renderCell: (params) => (
          <>
            <IconButton
              onClick={() => handleOpenJsonModal(params.row)}
              title="Edit Row Data (JSON)"
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={() =>
                handleSelectTournamentSeason(params.row.tournament_season_id)
              }
              title="Filter Tournament Season"
            >
              <FilterListIcon />
            </IconButton>
          </>
        ),
      },
      { field: "name", headerName: "Event Name", width: 250, editable: true },
      {
        field: "tournament_season_id",
        headerName: "Tournament Season ID",
        width: 50,
        editable: false,
      },
      {
        field: "start_time",
        headerName: "Start Time",
        width: 200,
        editable: true,
      },
      { field: "venue", headerName: "Venue", width: 100, editable: true },
      { field: "stage", headerName: "Stage", width: 70, editable: true },
      {
        field: "stage_type",
        headerName: "Stage Type",
        width: 100,
        editable: true,
      },
      { field: "group", headerName: "Group", width: 70, editable: true },
      { field: "country", headerName: "Country", width: 80, editable: true },
      { field: "distance", headerName: "Dist.", width: 70, editable: true },
      {
        field: "start_location",
        headerName: "Start",
        width: 100,
        editable: true,
      },
      {
        field: "finish_location",
        headerName: "Finish",
        width: 100,
        editable: true,
      },
    ],
    []
  );

  return (
    <div>
      <ParameterSelection />
      <DataTable />
      <ItemUpdateSummary />
      <ItemEditDialog />
    </div>
  );
};

export default EventsPerLeagueId;

import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
} from "@mui/material";
import { SportCreate } from "../types";

interface AddSportModalProps {
  open: boolean;
  onClose: () => void;
  newSport: SportCreate;
  setNewSport: (sport: {
    name: string;
    slug: string;
    icon_name: string;
  }) => void;
  handleAddSport: () => void;
}

const AddSportModal: React.FC<AddSportModalProps> = ({
  open,
  onClose,
  newSport,
  setNewSport,
  handleAddSport,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add New Sport</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="Name"
          fullWidth
          value={newSport.name}
          onChange={(e) => setNewSport({ ...newSport, name: e.target.value })}
        />
        <TextField
          margin="dense"
          label="Slug"
          fullWidth
          value={newSport.slug}
          onChange={(e) => setNewSport({ ...newSport, slug: e.target.value })}
        />
        <TextField
          margin="dense"
          label="Icon"
          fullWidth
          value={newSport.icon_name}
          onChange={(e) =>
            setNewSport({ ...newSport, icon_name: e.target.value })
          }
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleAddSport} variant="contained">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddSportModal;

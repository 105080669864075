import { z } from "zod";

import { eventResultSchema } from "./schema";
import axios from "../../api/axiosInstance";
import { EventUpdate } from "./types";

const eventResultsSchema = z.array(eventResultSchema);

/**
 * Fetch all LeagueSport results.
 */
export const fetchEventResultsByTournamentSeasonId = async (id: number) => {
  const { data } = await axios.get(`/event/by-tournament-season-id/${id}`);
  return eventResultsSchema.parse(data);
};

// Fetches all events by league ID, optional filter by tournament ID, start date, and end date
export const fetchEventsByLeagueId = async (
  leagueId?: number,
  tournamentId?: number,
  tournamentSeasonId?: number,
  startDate?: string,
  endDate?: string
) => {
  if (!leagueId) return [];
  const queryParams = new URLSearchParams();
  if (tournamentId)
    queryParams.append("tournament_id", tournamentId.toString());
  if (tournamentSeasonId)
    queryParams.append("tournament_season_id", tournamentSeasonId.toString());
  if (startDate) queryParams.append("start_date", startDate);
  if (endDate) queryParams.append("end_date", endDate);

  const { data } = await axios.get(
    `/event/by-league-id/${leagueId.toString()}${
      queryParams.toString() ? "?" + queryParams.toString() : ""
    }`
  );
  return eventResultsSchema.parse(data);
};

export const updateEvent = async (event: EventUpdate) => {
  const { data } = await axios.put(`/event/${event.id}`, event);
  return eventResultSchema.parse(data);
};

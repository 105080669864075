import React, { useState, useEffect, useMemo } from "react";
import {
  DataGrid,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { CircularProgress, Box, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useGetAllSports } from "../queries";
import { useUpdateSport, useCreateSport } from "../mutations";
import AddSportModal from "./AddSportModal";

const SportsOverview = () => {
  const { data, isLoading, error, refetch } = useGetAllSports();
  const [rows, setRows] = useState<any[]>([]);
  const { mutate: updateSport } = useUpdateSport();
  const { mutate: addSport } = useCreateSport();
  const [open, setOpen] = useState(false);
  const [newSport, setNewSport] = useState({
    name: "",
    slug: "",
    icon_name: "",
  });

  useEffect(() => {
    if (data) {
      setRows(data);
    }
  }, [data]);

  const processRowUpdate = async (newRow: any, oldRow: any) => {
    updateSport(newRow);
    return newRow;
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleAddSport = () => {
    addSport(newSport, {
      onSuccess: (addedSport) => {
        setRows((prev) => [...prev, addedSport]);
        setNewSport({ name: "", slug: "", icon_name: "" });
        handleClose();
      },
    });
  };

  const columns: GridColDef[] = useMemo(
    () => [
      { field: "id", headerName: "ID", width: 70 },
      { field: "name", headerName: "Name", width: 200, editable: true },
      { field: "slug", headerName: "Slug", width: 150, editable: true },
      { field: "icon_name", headerName: "Icon", width: 150, editable: true },
    ],
    []
  );

  const CustomToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarColumnsButton
        slotProps={{ button: { color: "secondary" } }}
      />
      <GridToolbarFilterButton slotProps={{ button: { color: "secondary" } }} />
      <IconButton onClick={handleOpen} color="secondary">
        <AddIcon />
      </IconButton>
      <IconButton onClick={() => refetch()} color="secondary">
        <RefreshIcon />
      </IconButton>
    </GridToolbarContainer>
  );

  if (isLoading) return <CircularProgress />;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <Box sx={{ height: 500, width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        editMode="row"
        processRowUpdate={processRowUpdate}
        slots={{ toolbar: CustomToolbar }}
        onProcessRowUpdateError={(error) =>
          console.error("Row update error:", error)
        }
      />
      <AddSportModal
        open={open}
        onClose={handleClose}
        newSport={newSport}
        setNewSport={setNewSport}
        handleAddSport={handleAddSport}
      />
    </Box>
  );
};

export default SportsOverview;

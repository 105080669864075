import { z } from "zod";

import { leagueResultSchema } from "./schema";
import axios from "../../api/axiosInstance";
import { LeagueCreate, LeagueUpdate } from "./types";

/**
 * Fetch all Leagues.
 */
export const fetchAllLeages = async () => {
  const { data } = await axios.get("/league");
  return z.array(leagueResultSchema).parse(data);
};

export const addLeague = async (league: LeagueCreate) => {
  const { data } = await axios.post("/league", { league });
  return leagueResultSchema.parse(data);
};

export const updateLeague = async (league: LeagueUpdate) => {
  const { data } = await axios.put(`/league/${league.id}`, league);
  return leagueResultSchema.parse(data);
};

import SettingsMenuBar from "../../components/SettingsMenu";
import LeagueOverview from "../../features/leagues/components/LeagueOverview";

const LeaguesPage = () => {
  return (
    <div>
      <SettingsMenuBar />
      <h1>Leagues</h1>
      <LeagueOverview />
    </div>
  );
};

export default LeaguesPage;

import React, { useEffect, useState } from "react";
import { useDeleteCache } from "../mutations";
import { Button } from "@mui/material";

const DeleteCacheButton: React.FC = () => {
  const [buttonText, setButtonText] = useState("Delete Cache");
  const { mutate, isPending, isSuccess } = useDeleteCache();

  useEffect(() => {
    if (isPending) {
      setButtonText("Deleting Cache...");
    } else if (isSuccess) {
      setButtonText("Cache Deleted");
    } else {
      setButtonText("Delete Cache");
    }
  }, [isPending, isSuccess]);

  return (
    <Button
      onClick={() => mutate()}
      disabled={isPending}
      color="primary"
      variant="contained"
    >
      {buttonText}
    </Button>
  );
};

export default DeleteCacheButton;

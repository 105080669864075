import { useQuery } from "@tanstack/react-query";

import { fetchTournamentSeasonsByLeagueId } from "./api";
import { TournamentSeasonResultSchema } from "./types";

/**
 * React Query hook to fetch event results by tournament season id.
 */
export const useGetTournamentSeasonsByLeagueId = (
  id?: number,
  startDate?: string,
  endDate?: string
) => {
  return useQuery<TournamentSeasonResultSchema[]>({
    queryKey: ["tournamentSeasonByLeagueId", id, startDate, endDate],
    queryFn: () => fetchTournamentSeasonsByLeagueId(id, startDate, endDate),
    enabled: !!id,
  });
};

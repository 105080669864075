import EventsPerLeagueId from "../../features/events/components/eventsPerLeague";
import SettingsMenuBar from "../../components/SettingsMenu";

const EventsPerLeaguePage = () => {
  return (
    <div>
      <SettingsMenuBar />
      <h1>Events</h1>
      <EventsPerLeagueId />
    </div>
  );
};

export default EventsPerLeaguePage;

import { z } from "zod";

import axios from "../../api/axiosInstance";
import {
  tournamentSeasonResultSchema,
  tournamentSeasonSyncResultSchema,
} from "./schema";
import {
  TournamentSeasonCreateSchema,
  TournamentSeasonUpdateSchema,
} from "./types";

const tournamentSeasonResultsSchema = z.array(tournamentSeasonResultSchema);

/**
 * Fetch all LeagueSport results.
 */
export const fetchTournamentSeasonsByLeagueId = async (
  id?: number,
  startDate?: string,
  endDate?: string
) => {
  // Retun an empty array if no id is provided
  if (!id) return [];

  // Create query params
  const queryParams = new URLSearchParams();
  if (startDate) queryParams.append("start_date", startDate);
  if (endDate) queryParams.append("end_date", endDate);

  // Fetch data with query params
  const { data } = await axios.get(
    `/tournament-season/by-league-id/${id}${
      queryParams.toString() ? "?" + queryParams.toString() : ""
    }`
  );
  return tournamentSeasonResultsSchema.parse(data);
};

export const createTournamentSeason = async (
  season: TournamentSeasonCreateSchema
) => {
  const { data } = await axios.post("/tournament-season", season);
  return tournamentSeasonResultSchema.parse(data);
};

export const updateTournamentSeason = async (
  tournamentSeason: TournamentSeasonUpdateSchema
) => {
  const { data } = await axios.put(
    `/tournament-season/${tournamentSeason.id}`,
    tournamentSeason
  );
  return tournamentSeasonResultSchema.parse(data);
};

export const syncTournamentSeasonEvents = async (id: number) => {
  const { data } = await axios.put(`/tournament-season/${id}/sync-events`);
  return tournamentSeasonSyncResultSchema.parse(data);
};

import React, { useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  Navigate,
} from "react-router-dom";
import LandingPage from "./pages/landing";
import PrivacyPolicy from "./pages/privacy";
import SettingsPage from "./pages/settings";
import {
  Box,
  CircularProgress,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import EventsPerLeaguePage from "./pages/settings/EventsPage";
import TournamentSeasonsPerLeaguePage from "./pages/settings/TournamentSeasonsPage";
import SportsPage from "./pages/settings/SportsPage";
import LeaguesPage from "./pages/settings/LeaguesPage";
import TournamentsPage from "./pages/settings/TournamentsPage";
import ConfigPage from "./pages/settings/ConfigPage";
import { useIsAuthenticated } from "./features/cache/queries";
import MetadataPage from "./pages/settings/MetadataPage";

function ProtectedRoute({ children }: { children: JSX.Element }) {
  const [isAllowed, setIsAllowed] = useState<boolean | null>(null);
  const { data: isAuth, isFetching, error } = useIsAuthenticated();

  useEffect(() => {
    const checkAccess = async () => {
      if (isFetching) return;
      if (error) {
        console.error("Not authenticated");
        setIsAllowed(false);
        return;
      }
      try {
        setIsAllowed(isAuth || false); // Assuming the API returns { allowed: true/false }
      } catch (error) {
        console.error("Error checking access:", error);
        setIsAllowed(false);
      }
    };

    checkAccess();
  }, [error, isAuth, isFetching]);

  if (isAllowed === null) {
    return <CircularProgress />;
  }

  return isAllowed ? children : <Navigate to="/settings" />;
}

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 1000 * 60 * 15, // Data will not be considered stale for 15 minutes
      },
    },
  });

  const createMuiTheme = () =>
    createTheme({
      palette: {
        primary: {
          main: "rgb(254, 215, 9)",
        },
        secondary: {
          main: "rgb(125, 125, 130)",
        },
        error: {
          main: "rgb(255, 69, 58)",
        },
      },
    });

  return (
    <ThemeProvider theme={createMuiTheme}>
      <QueryClientProvider client={queryClient}>
        <Router>
          <Box sx={{ padding: 2 }}>
            <Routes>
              <Route path="/privacy" element={<PrivacyPolicy />} />
              <Route path="/" element={<LandingPage />} />
              <Route path="/settings" element={<SettingsPage />} />
              <Route
                path="/settings/config"
                element={
                  <ProtectedRoute>
                    <ConfigPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/settings/metadata"
                element={
                  <ProtectedRoute>
                    <MetadataPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/settings/sports"
                element={
                  <ProtectedRoute>
                    <SportsPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/settings/leagues"
                element={
                  <ProtectedRoute>
                    <LeaguesPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/settings/tournaments"
                element={
                  <ProtectedRoute>
                    <TournamentsPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/settings/seasons"
                element={
                  <ProtectedRoute>
                    <TournamentSeasonsPerLeaguePage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/settings/events"
                element={
                  <ProtectedRoute>
                    <EventsPerLeaguePage />
                  </ProtectedRoute>
                }
              />
            </Routes>
          </Box>
        </Router>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;

import { useMutation } from "@tanstack/react-query";
import { createSport, updateSport } from "./api";

export function useCreateSport() {
  return useMutation({
    mutationFn: createSport,
  });
}

export function useUpdateSport() {
  return useMutation({
    mutationFn: updateSport,
  });
}

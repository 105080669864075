import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  CircularProgress,
} from "@mui/material";
import { TournamentSeasonCreateSchema } from "../types"; // Adjust type import as needed
import { useGetAllLeagues } from "../../leagues/queries";
import { useGetTournamentsByLeagueId } from "../../tournaments/queries";

interface AddTournamentSeasonModalProps {
  open: boolean;
  onClose: () => void;
  newTournamentSeason: TournamentSeasonCreateSchema;
  setNewTournamentSeason: (season: TournamentSeasonCreateSchema) => void;
  handleAddTournamentSeason: () => void;
  preselectedLeagueId?: number;
}

const AddTournamentSeasonModal: React.FC<AddTournamentSeasonModalProps> = ({
  open,
  onClose,
  newTournamentSeason,
  setNewTournamentSeason,
  handleAddTournamentSeason,
  preselectedLeagueId,
}) => {
  const { data: leagues, isLoading: leaguesLoading } = useGetAllLeagues();
  const [id, setId] = useState<number | undefined>(preselectedLeagueId);

  const {
    data: tournaments,
    isLoading: tournamentsLoading,
    error: tournamentsError,
  } = useGetTournamentsByLeagueId(id);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add New Tournament Season</DialogTitle>
      <DialogContent>
        <FormControl fullWidth sx={{ marginTop: 2 }}>
          <InputLabel id="league-id">League</InputLabel>
          <Select
            labelId="league-id"
            id="league-id"
            value={id}
            label="League"
            onChange={(e) => setId(Number(e.target.value))}
            disabled={leaguesLoading} // Disable dropdown while loading
          >
            {leaguesLoading ? (
              <MenuItem disabled>
                <CircularProgress size={24} />
              </MenuItem>
            ) : (
              leagues?.map((league) => (
                <MenuItem key={league.id} value={league.id}>
                  {league.name} - {league.country} ({league.gender})
                </MenuItem>
              ))
            )}
          </Select>
        </FormControl>
        <FormControl fullWidth margin="normal">
          <InputLabel id="tournament-id">Tournament</InputLabel>
          <Select
            labelId="tournament-id"
            id="tournament-id"
            value={newTournamentSeason.tournament_id}
            label="Tournament"
            onChange={(e) =>
              setNewTournamentSeason({
                ...newTournamentSeason,
                tournament_id: Number(e.target.value),
              })
            }
            disabled={tournamentsLoading}
          >
            {tournamentsLoading ? (
              <MenuItem disabled>
                <CircularProgress size={24} />
              </MenuItem>
            ) : tournamentsError ? (
              <MenuItem disabled>Error loading</MenuItem>
            ) : (
              tournaments?.map((tournament) => (
                <MenuItem key={tournament.id} value={tournament.id}>
                  {tournament.name}
                </MenuItem>
              ))
            )}
          </Select>
        </FormControl>

        <TextField
          margin="dense"
          label="Name"
          fullWidth
          value={newTournamentSeason.name}
          onChange={(e) =>
            setNewTournamentSeason({
              ...newTournamentSeason,
              name: e.target.value,
            })
          }
        />
        <TextField
          margin="dense"
          label="Slug"
          fullWidth
          value={newTournamentSeason.slug}
          onChange={(e) =>
            setNewTournamentSeason({
              ...newTournamentSeason,
              slug: e.target.value,
            })
          }
        />
        <TextField
          margin="dense"
          label="Season"
          fullWidth
          value={newTournamentSeason.season}
          onChange={(e) =>
            setNewTournamentSeason({
              ...newTournamentSeason,
              season: e.target.value,
            })
          }
        />
        <TextField
          margin="dense"
          label="Start Date"
          type="date"
          fullWidth
          value={newTournamentSeason.start_date || ""}
          onChange={(e) =>
            setNewTournamentSeason({
              ...newTournamentSeason,
              start_date: e.target.value,
            })
          }
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          margin="dense"
          label="End Date"
          type="date"
          fullWidth
          value={newTournamentSeason.end_date || ""}
          onChange={(e) =>
            setNewTournamentSeason({
              ...newTournamentSeason,
              end_date: e.target.value,
            })
          }
          InputLabelProps={{ shrink: true }}
        />
        <FormControl fullWidth margin="normal">
          <InputLabel id="source-id">Source</InputLabel>
          <Select
            labelId="source-id"
            id="source-id"
            value={newTournamentSeason.source}
            label="Source"
            onChange={(e) =>
              setNewTournamentSeason({
                ...newTournamentSeason,
                source: e.target.value as "thesportsdb" | "manual",
              })
            }
          >
            <MenuItem value="thesportsdb">TheSportsDB</MenuItem>
            <MenuItem value="manual">Manual</MenuItem>
          </Select>
        </FormControl>
        <TextField
          margin="dense"
          label="SDB League ID"
          type="number"
          fullWidth
          disabled={newTournamentSeason.source !== "thesportsdb"}
          value={newTournamentSeason.sdb_league_id || ""}
          onChange={(e) =>
            setNewTournamentSeason({
              ...newTournamentSeason,
              sdb_league_id: Number(e.target.value),
            })
          }
        />
        <TextField
          margin="dense"
          label="SDB Season"
          fullWidth
          disabled={newTournamentSeason.source !== "thesportsdb"}
          value={newTournamentSeason.sdb_season || ""}
          onChange={(e) =>
            setNewTournamentSeason({
              ...newTournamentSeason,
              sdb_season: e.target.value,
            })
          }
        />
        <TextField
          margin="dense"
          label="Update Interval"
          type="number"
          fullWidth
          disabled={newTournamentSeason.source === "manual"}
          value={newTournamentSeason.update_interval || ""}
          onChange={(e) =>
            setNewTournamentSeason({
              ...newTournamentSeason,
              update_interval: Number(e.target.value),
            })
          }
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleAddTournamentSeason}
          variant="contained"
          color="primary"
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddTournamentSeasonModal;

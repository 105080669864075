import { z } from "zod";

import { tournamentResultSchema } from "./schema";
import axios from "../../api/axiosInstance";
import { TournamentCreate, TournamentUpdate } from "./types";

export const fetchTournamentsByLeagueId = async (leagueId?: number) => {
  if (!leagueId) {
    return [];
  }
  const { data } = await axios.get(`/tournament/by-league-id/${leagueId}`);
  return z.array(tournamentResultSchema).parse(data);
};

export const addTournament = async (tournament: TournamentCreate) => {
  const { data } = await axios.post("/tournament", tournament);
  return tournamentResultSchema.parse(data);
};

export const updateTournament = async (tournament: TournamentUpdate) => {
  const { data } = await axios.put(`/tournament/${tournament.id}`, tournament);
  return tournamentResultSchema.parse(data);
};

import React, { useEffect, useState } from "react";
import { useGetTournamentSeasonsByLeagueId } from "../queries";
import {
  DataGrid,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { useGetAllLeagues } from "../../leagues/queries";
import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import {
  useCreateTournamentSeason,
  useSyncTournamentSeasonEvents,
  useUpdateTournamentSeason,
} from "../mutations";
import AddTournamentSeasonModal from "./AddTournamentSeasonModal";
import {
  TournamentSeasonCreateSchema,
  TournamentSeasonResultSchema,
} from "../types";

const TournamentSeasonsPerLeagueId: React.FC = () => {
  // States used in form
  const [id, setId] = useState<number | undefined>(undefined);
  const [startDate, setStartDate] = useState<string | undefined>(undefined);
  const [endDate, setEndDate] = useState<string | undefined>(undefined);

  // States used in fetching data
  const [submittedId, setSubmittedId] = useState<number | undefined>(undefined);
  const [submittedStartDate, setSubmittedStartDate] = useState<
    string | undefined
  >(undefined);
  const [submittedEndDate, setSubmittedEndDate] = useState<string | undefined>(
    undefined
  );

  const [open, setOpen] = useState(false);
  const [newSeason, setNewSeason] = useState<TournamentSeasonCreateSchema>({
    tournament_id: 0,
    name: "",
    slug: "",
    season: "",
    start_date: "",
    end_date: "",
    source: "thesportsdb",
    update_interval: null,
    sdb_league_id: null,
    sdb_season: null,
  });
  const [editedRows, setEditedRows] = useState<
    TournamentSeasonResultSchema[] | undefined
  >(undefined);

  // Fetch all leagues
  const {
    data: leagues,
    isLoading: leaguesLoading,
    error: leaguesError,
  } = useGetAllLeagues();

  // Fetch events by league id and date range
  const { data, refetch } = useGetTournamentSeasonsByLeagueId(
    submittedId,
    submittedStartDate,
    submittedEndDate
  );

  // Mutation to sync events for a given tournament season
  const {
    mutate: syncEvents,
    data: syncResult,
    isError: isSyncError,
    error: syncError,
    isPending: isSyncPending,
  } = useSyncTournamentSeasonEvents();

  // Mutation to update a tournament season
  const {
    mutate: update,
    isError: isUpdateError,
    error: updateError,
    isSuccess: isUpdateSuccess,
  } = useUpdateTournamentSeason();
  const { mutate: createSeason } = useCreateTournamentSeason();

  useEffect(() => {
    if (data) {
      setEditedRows(data);
    }
  }, [data]);

  const handleFetchData = () => {
    setSubmittedId(id);
    setSubmittedStartDate(startDate);
    setSubmittedEndDate(endDate);
  };

  const handleActionClick = (row: any) => {
    syncEvents(row.id);
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleAddSeason = () => {
    createSeason(newSeason, {
      onSuccess: (addedSeason) => {
        setEditedRows((prev) => [...(prev || []), addedSeason]);
        setNewSeason({
          tournament_id: 0,
          name: "",
          slug: "",
          season: "",
          start_date: "",
          end_date: "",
          source: "thesportsdb",
          update_interval: null,
          sdb_league_id: null,
          sdb_season: null,
        });
        handleClose();
      },
    });
  };
  const CustomToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarColumnsButton
        slotProps={{ button: { color: "secondary" } }}
      />
      <GridToolbarFilterButton slotProps={{ button: { color: "secondary" } }} />
      <IconButton onClick={handleOpen} color="secondary">
        <AddIcon />
      </IconButton>
      <IconButton onClick={() => refetch()} color="secondary">
        <RefreshIcon />
      </IconButton>
    </GridToolbarContainer>
  );

  const ParameterSelection = () => {
    // Show error when loading leagues fails
    if (leaguesError)
      return <Alert severity="error">{leaguesError.message}</Alert>;

    // Otherwise render form
    return (
      <Box display="block" mb={3}>
        <FormControl fullWidth>
          <InputLabel id="league-id">League ID</InputLabel>
          <Select
            labelId="league-id"
            id="league-id"
            value={id}
            label="League ID"
            onChange={(e) => {
              setNewSeason((prev) => ({
                ...prev,
                tournament_id: Number(e.target.value),
              }));
              setId(Number(e.target.value));
            }}
            disabled={leaguesLoading} // Disable dropdown while loading
          >
            {leaguesLoading ? (
              <MenuItem disabled>
                <CircularProgress size={24} />
              </MenuItem>
            ) : (
              leagues?.map((league) => (
                <MenuItem key={league.id} value={league.id}>
                  {league.id} {league.name} - {league.country} ({league.gender})
                </MenuItem>
              ))
            )}
          </Select>
        </FormControl>
        <TextField
          label="Start Date"
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="End Date"
          type="date"
          value={endDate || ""}
          onChange={(e) => setEndDate(e.target.value)}
          fullWidth
          margin="normal"
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleFetchData}
          disabled={!id && !startDate && !endDate}
          fullWidth
        >
          Search
        </Button>
      </Box>
    );
  };

  const DataTable = () => {
    return (
      <>
        <div style={{ height: 500, width: "100%" }}>
          <DataGrid
            editMode="row"
            rows={editedRows}
            columns={eventColumns}
            slots={{ toolbar: CustomToolbar }}
            processRowUpdate={processRowUpdate} // Call mutation when editing
            onProcessRowUpdateError={(error) =>
              console.error("Row update error:", error)
            }
          />
        </div>
        <Box display="flex" justifyContent="flex-end" mb={1}>
          <Button variant="contained" color="primary" onClick={() => refetch()}>
            Refresh
          </Button>
        </Box>
      </>
    );
  };

  const SyncUpdateSummary = () => {
    if (isSyncError) return <Alert severity="error">{syncError.message}</Alert>;
    if (!syncResult) return null;

    return (
      <Alert severity="success">
        {syncResult.tournament_season_name}: {syncResult.updated} updated,{" "}
        {syncResult.added} added
      </Alert>
    );
  };

  const ItemUpdateSummary = () => {
    if (isUpdateError)
      return <Alert severity="error">{updateError.message}</Alert>;
    if (isUpdateSuccess)
      return <Alert severity="success">Update success</Alert>;
    return null;
  };

  const processRowUpdate = async (newRow: any, oldRow: any) => {
    try {
      update(newRow); // Call API to update
      setEditedRows((prev) =>
        prev?.map((row) => (row.id === newRow.id ? newRow : row))
      );
      return newRow; // Return updated row
    } catch (error) {
      console.error("Error updating row:", error);
      return oldRow; // Revert on failure
    }
  };

  const eventColumns: GridColDef[] = [
    { field: "name", headerName: "Name", width: 250, editable: true },
    { field: "season", headerName: "Season", width: 150, editable: true },
    {
      field: "start_date",
      headerName: "Start Date",
      width: 200,
      editable: true,
    },
    { field: "end_date", headerName: "End Date", width: 200, editable: true },
    { field: "source", headerName: "Source", width: 200, editable: false },
    {
      field: "sdb_league_id",
      headerName: "SDB league ID",
      width: 200,
      editable: true,
    },
    {
      field: "sdb_season",
      headerName: "SDB season",
      width: 200,
      editable: true,
    },
    {
      field: "update_interval",
      headerName: "Update Interval",
      width: 150,
      editable: true,
    },
    {
      field: "last_update",
      headerName: "Last Update",
      width: 200,
      editable: false,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="secondary"
          onClick={() => handleActionClick(params.row)}
        >
          Sync
        </Button>
      ),
    },
  ];

  return (
    <div>
      <AddTournamentSeasonModal
        open={open}
        onClose={handleClose}
        newTournamentSeason={newSeason}
        setNewTournamentSeason={setNewSeason}
        handleAddTournamentSeason={handleAddSeason}
        preselectedLeagueId={submittedId}
      />{" "}
      <ParameterSelection />
      <SyncUpdateSummary />
      <ItemUpdateSummary />
      {isSyncPending && <CircularProgress size={24} />}
      <DataTable />
    </div>
  );
};

export default TournamentSeasonsPerLeagueId;

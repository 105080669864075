import { z } from "zod";

export const sportCreateSchema = z.object({
  name: z.string(),
  slug: z.string(),
  icon_name: z.string(),
});

export const sportUpdateSchema = z.object({
  id: z.number(),
  name: z.string().optional(),
  slug: z.string().optional(),
  icon_name: z.string().optional(),
});

export const sportResultSchema = sportCreateSchema.extend({
  id: z.number(),
});

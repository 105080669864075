import { useQuery } from "@tanstack/react-query";

import { fetchAllLeages } from "./api";
import { LeagueResult } from "./types";

/**
 * React Query hook to fetch all Leagues.
 */
export const useGetAllLeagues = () => {
  return useQuery<LeagueResult[]>({
    queryKey: ["leagues"],
    queryFn: () => fetchAllLeages(),
  });
};

import React, { useState, useEffect } from "react";
import { Box, TextField, Button, Typography, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import DeleteCacheButton from "../features/cache/components/deleteCacheButton";
import SettingsMenuBar from "../components/SettingsMenu";

const SettingsPage: React.FC = () => {
  const [authKey, setAuthKey] = useState("");
  const [storedKey, setStoredKey] = useState<string | null>("");
  const navigate = useNavigate();

  useEffect(() => {
    // Load the current authKey from localStorage when the component mounts
    const key = localStorage.getItem("authKey");
    setStoredKey(key);
  }, []);

  const handleSave = () => {
    localStorage.setItem("authKey", authKey);
    setStoredKey(authKey); // Update the displayed value
    setAuthKey(""); // Clear the input field
  };

  const handleNavigateToEvents = () => {
    navigate("/settings/events-per-league");
  };
  const handleNavigateToTournamentSeasons = () => {
    navigate("/settings/tournament-seasons-per-league");
  };

  return (
    <>
      <SettingsMenuBar />
      <Container maxWidth="sm">
        <Box
          sx={{
            padding: 4,
            textAlign: "center",
            fontFamily: "Arial, sans-serif",
          }}
        >
          <Typography variant="h4" gutterBottom>
            Set Auth Key
          </Typography>
          <TextField
            label="Auth Key"
            value={authKey}
            onChange={(e) => setAuthKey(e.target.value)}
            fullWidth
            margin="normal"
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            sx={{ marginTop: 2 }}
          >
            Save
          </Button>
          <Typography variant="h6" sx={{ marginTop: 4 }}>
            Current Stored Auth Key:
          </Typography>
          <Typography variant="body1" sx={{ wordBreak: "break-all" }}>
            {storedKey
              ? `${storedKey.slice(0, 2)}...${storedKey.slice(-2)}`
              : "No key set"}
          </Typography>
          <Box
            sx={{
              marginTop: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <DeleteCacheButton />
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default SettingsPage;

import SettingsMenuBar from "../../components/SettingsMenu";
import TournamentSeasonsPerLeagueId from "../../features/tournament_seasons/components/tournamentSeasonsPerLeagueId";

const TournamentSeasonsPerLeaguePage = () => {
  return (
    <div>
      <SettingsMenuBar />
      <h1>Tournament seasons</h1>
      <TournamentSeasonsPerLeagueId />
    </div>
  );
};

export default TournamentSeasonsPerLeaguePage;

import { useQuery } from "@tanstack/react-query";

import { fetchEventsByLeagueId } from "./api";
import { EventResult } from "./types";

export const useEventResultsByLeagueId = (
  leagueId?: number,
  tournamentId?: number,
  tournamentSeasonId?: number,
  startDate?: string,
  endDate?: string
) => {
  return useQuery<EventResult[]>({
    queryKey: [
      "eventResultsByLeagueId",
      leagueId,
      tournamentId,
      tournamentSeasonId,
      startDate,
      endDate,
    ],
    queryFn: () =>
      fetchEventsByLeagueId(
        leagueId,
        tournamentId,
        tournamentSeasonId,
        startDate,
        endDate
      ),
    enabled: !!leagueId,
  });
};

import * as React from "react";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Container,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";

const pages = {
  Sports: { path: "/settings/sports", label: "Sports" },
  Leagues: { path: "/settings/leagues", label: "Leagues" },
  Tournaments: { path: "/settings/tournaments", label: "Tournaments" },
  Seasons: { path: "/settings/seasons", label: "Seasons" },
  Events: { path: "/settings/events", label: "Events" },
  Config: { path: "/settings/config", label: "Config" },
  Metadata: { path: "/settings/metadata", label: "Metadata" },
};

const SettingsMenuBar = () => {
  const navigation = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const hanleNavClick = (page: string) => {
    navigation(page);
    handleCloseNavMenu();
  };

  return (
    <AppBar
      position="static"
      sx={{ backgroundColor: "rgb(254, 215, 9)", color: "black" }}
    >
      <Container maxWidth="xl">
        <Toolbar>
          <Typography
            variant="h6"
            component="a"
            onClick={() => navigation("/settings")}
            sx={{
              mr: 2,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            Sports Today
          </Typography>
          <Box sx={{ flexGrow: 1 }}>
            <IconButton
              size="large"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorElNav}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              transformOrigin={{ vertical: "top", horizontal: "left" }}
            >
              {Object.values(pages).map((page) => (
                <MenuItem
                  key={page.label}
                  onClick={(e) => hanleNavClick(page.path)}
                >
                  <Typography textAlign="center">{page.label}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default SettingsMenuBar;
